import { AutocompleteField } from './../../forms/autocomplete-field/autocomplete-field.component';

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { OptionColumns } from 'src/app/components/forms/autocomplete-field/autocomplete-field.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { Dapc } from 'src/app/models/Dapc';
import { DapcContactDialog } from '../dapccontactdialog/dapc-contact-dialog.component';
import { AlertDialog } from '../alertdialog/alert-dialog.component';
import { SendDapcDialog } from 'src/app/modules/admin/dapcs/components/send-dapc-dialog/send-dapc-dialogcomponent';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';


@Component({
  selector: 'app-dapc-dialog',
  templateUrl: './dapc-dialog.component.html',
  styleUrls: ['./dapc-dialog.component.scss'],
})
export class DapcDialog implements OnInit {

  public btn_save_disabled = false;
  public btn_save_loader = false;   
  public btn_send_disabled = false;
  public btn_send_loader = false;   
  public dapc: Dapc = null;
  public falseValue = "false";
  public trueValue = "true";
  public readonly=false;
  public from_dict=false;
  public send_email=false;

  public maxLenght_duration=3;
  public maxLenght_width=2;
  public maxLenght_road=2;
  public maxLenght_speed=2;

  public dict_radio = false;
  public label_radio = false;

  //auto complete
  public OptionsGroupColumns : OptionColumns= {
    id:'id',
    label:['firstname','lastname','city'],
  }

  public OptionsGroupColumnsDict : OptionColumns= {
    id:'id',
    label:['noAffaireDuResponsableDuProjet','noConsultationDuTeleservice'],
  }

  public responsableExtraParameters = {
    filters:
      [
        { column: 'is_beuzit', operator: '=', value: 0 },
      ]
  }

  public applicantExtraParameters = {
    filters:
      [
        { column: 'is_beuzit', operator: '=', value: 1 },
      ]
  }

  
  @ViewChild('autocompleteField', { static: false }) AutocompleteField: AutocompleteField;
  @ViewChild('autocompleteFieldDict', { static: false }) AutocompleteFieldDict: AutocompleteField;


  //forms
  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DapcDialog>,
    private translate: TranslateService,
    public auth: AuthenticationService,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog
  ) {
    // create forms
    this.form = new FormGroup(
      {
        'contact_id': new FormControl('', [Validators.required]),
        'dict_id': new FormControl('', []),
        'label': new FormControl(null,[]),
        'beuzit_contact_id':new FormControl('', [Validators.required]),
        'debutDate':new FormControl(null),
        'debutDateReglementation':new FormControl(null),
        
        //localization
        'DA.localisation.numAutoroute' : new FormControl(''),
        'DA.localisation.numRteNat': new FormControl(''),
        'DA.localisation.numRteDep': new FormControl(''),
        'DA.localisation.numVoieCommunale':new FormControl(''),
        'DA.localisation.horsAgglo':new FormControl(''),//checkbox
        'DA.localisation.enAgglo':new FormControl(''),//checkbox
        'DA.localisation.prDebut':new FormControl(''),
        'DA.localisation.decalageDebut':new FormControl(''),     
        'DA.localisation.prDebutCheck':new FormControl(''),//checkbox
        'DA.localisation.prFin':new FormControl(''),
        'DA.localisation.decalageFin':new FormControl(''),
        'DA.localisation.prFinCheck':new FormControl(''),//checkbox       
        'DA.localisation.num': new FormControl(''),
        'DA.localisation.extension':new FormControl(''),
        'DA.localisation.rue':new FormControl(''),
        'DA.localisation.complement':new FormControl(''),
        'DA.localisation.cp':new FormControl(''),  
        'DA.localisation.localite':new FormControl(''),

        //nature
        'DA.nature.permissionAnterieureOui':new FormControl(''),//checkbox
        'DA.nature.permissionAnterieureNon' :new FormControl(''),//checkbox
        'DA.nature.refPermission':new FormControl(''),
        'DA.nature.description':new FormControl(''),
        'DA.nature.numeroProjet':new FormControl(''),
        'DA.nature.duree':new FormControl('',[Validators.pattern("^[0-9]*$"),Validators.maxLength(this.maxLenght_duration)]),

        //reglementation
        'DA.reglementation.duree':new FormControl('',[Validators.pattern("^[0-9]*$"),Validators.maxLength(this.maxLenght_duration)]),
        'DA.reglementation.restrictionSectionCourante':new FormControl(''),//check
        'DA.reglementation.restrictionBretelles':new FormControl(''),//check
        'DA.reglementation.deuxSensCirculation':new FormControl(''),//check
        'DA.reglementation.prCroissant':new FormControl(''),//checkbox
        'DA.reglementation.prDecroissant':new FormControl(''),
        'DA.reglementation.fermetureCirculation':new FormControl(''),//checkbox
        'DA.reglementation.basculementCirculationChausseeOpposee':new FormControl(''),
        'DA.reglementation.feuxTricolores':new FormControl(''),//check
        'DA.reglementation.manuellement':new FormControl(''),//check
        'DA.reglementation.bandeArretUrgence':new FormControl(''),//check
        'DA.reglementation.empietementChausee':new FormControl(''),//cechk
        'DA.reglementation.largeurMaintenue':new FormControl('',[Validators.pattern("^[0-9]+\.?[0-9]*|\.[0-9]$"),Validators.maxLength(this.maxLenght_width)]),
        'DA.reglementation.suppressionVoie':new FormControl(''),//check
        'DA.reglementation.nombreVoieSupprimee':new FormControl('',[Validators.pattern("^[0-9]*$"),Validators.maxLength(this.maxLenght_road)]),        
        'DA.reglementation.interdictionDepasserPoidsLourds':new FormControl(''),
        'DA.reglementation.interdictionStationnerPoidsLourds':new FormControl(''),
        'DA.reglementation.interdictionCirculerPoidsLourds':new FormControl(''),
        'DA.reglementation.interdictionDepasserVehiculesLegers':new FormControl(''),
        'DA.reglementation.interdictionStationnerVehiculesLegers':new FormControl(''),
        'DA.reglementation.interdictionCirculerVehiculesLegers':new FormControl(''),
        'DA.reglementation.vitesseLimitee': new FormControl('',[Validators.pattern("^[0-9]*$"),Validators.maxLength(this.maxLenght_speed)]),
        'DA.reglementation.itineraireDeviation': new FormControl(''),
        'DA.reglementation.autresPrescriptions':new FormControl('')
      }
    );

  }

  ngOnInit(): void {

    if (this.data && this.data.item) {

      //import data
      this.dapc=new Dapc(this.data.item);

      //patch values
      this.form.controls['contact_id'].patchValue({
        id: this.dapc.dapc_contact_id,
        label: 
          this.dapc.firstname +' '+
          this.dapc.lastname +' '+
          this.dapc.city        
      });
      this.form.controls['beuzit_contact_id'].patchValue({
        id: this.dapc.beuzit_contact_id,
        label: 
          this.dapc.beuzit_contact_firstname+' '+
          this.dapc.beuzit_contact_lastname+' '+     
          this.dapc.beuzit_contact_city        
      });

      if(this.dapc.dict_id){
        this.form.controls['dict_id'].patchValue({
          id: this.dapc.dict_id,
          label: this.dapc.noAffaireDuResponsableDuProjet ? 
                 this.dapc.noAffaireDuResponsableDuProjet +' '+ this.dapc.noConsultationDuTeleservice :
                 this.dapc.noConsultationDuTeleservice
        });
        this.updateDictIdRadio();
        this.dict_radio=true;
      }

      if(this.dapc.label && !this.dapc.dict_id){
        this.form.controls['label'].patchValue(this.dapc.label);
        this.updateLabelRadio();
        this.label_radio = true;
      }

      //if there are dates, patch
      if(this.dapc.data['DA.nature.dateDebut.yyyy'] && 
         this.dapc.data['DA.nature.dateDebut.mm'] && 
         this.dapc.data['DA.nature.dateDebut.dd']){
      
        this.form.controls['debutDate'].patchValue(
          new Date(this.dapc.data['DA.nature.dateDebut.yyyy'],
                   this.dapc.data['DA.nature.dateDebut.mm']-1,
                   this.dapc.data['DA.nature.dateDebut.dd']));
      }
      if(this.dapc.data['DA.reglementation.dateDebut.yyyy'] &&
         this.dapc.data['DA.reglementation.dateDebut.mm'] &&
         this.dapc.data['DA.reglementation.dateDebut.dd']){

        this.form.controls['debutDateReglementation'].patchValue(
          new Date(this.dapc.data['DA.reglementation.dateDebut.yyyy'], 
                   this.dapc.data['DA.reglementation.dateDebut.mm']-1, 
                   this.dapc.data['DA.reglementation.dateDebut.dd']));  
      }
      //path the rest of the values  
      this.form.patchValue(this.dapc.data);   

      //if sent -> disable
      if(this.dapc && (this.dapc.status == 'sent' || this.dapc.status == 'validated')){
        this.form.disable();
      }

      
    }
    //if dialog opened from dict page
    else if(this.data && this.data?.dict){
          
      //path only with dict data
      if(this.data.dict.id){
        this.form.controls['dict_id'].patchValue({
          id: this.data.dict.id,
          label:  this.data.dict.noAffaireDuResponsableDuProjet ? 
                  this.data.dict.noAffaireDuResponsableDuProjet +' '+ this.data.dict.noConsultationDuTeleservice :
                  this.data.dict.noConsultationDuTeleservice
        });
      }
      
      //make field readonly
      this.updateDictIdRadio();
      this.from_dict=true;      
      this.dict_radio=true;

      this.onDictSelect(this.data.dict.id);
    }
    else{

      this.updateDictIdRadio();
      this.dict_radio=true;
    }
    
  }

  checkboxChange(checkbox: MatCheckbox, checked: boolean) {
    checkbox.value = checked ? this.trueValue : this.falseValue;
  }

  onRadioChange(radio: MatRadioButton){ 
    switch(radio?.id){
      case 'label_radio':{
       this.updateLabelRadio();
      }        
      break;
      case 'dict_radio':{
        this.updateDictIdRadio();
      }        
      break;
      default:
      break;
    }
  }

  updateLabelRadio(){
    this.resetAdressFormControls();
    this.AutocompleteFieldDict.clearControl();
    this.dict_radio = false;
    this.label_radio = true;
    this.form.controls.label.enable();   
    this.form.controls.dict_id.setValue('');         
    this.form.controls.dict_id.disable();
    this.form.controls.label.setValidators(Validators.required);
    this.form.controls.dict_id.clearValidators();
    this.form.controls.label.updateValueAndValidity();
    this.form.controls.dict_id.updateValueAndValidity();
  }

  updateDictIdRadio(){
    this.resetAdressFormControls();
    this.dict_radio = true;
    this.label_radio = false;
    this.form.controls.label.disable();
    this.form.controls.label.clearValidators();
    this.form.controls.label.updateValueAndValidity();   
    this.form.controls.dict_id.enable();    
    this.form.controls.dict_id.setValidators(Validators.required);
    this.form.controls.dict_id.updateValueAndValidity();
    this.form.controls.dict_id.markAsUntouched();
  }

  resetAdressFormControls(){
    this.form.controls['DA.localisation.num'].setValue('');
    this.form.controls['DA.localisation.extension'].setValue('');
    this.form.controls['DA.localisation.rue'].setValue('');
    this.form.controls['DA.localisation.complement'].setValue('');
    this.form.controls['DA.localisation.cp'].setValue('');
    this.form.controls['DA.localisation.localite'].setValue('');
    this.form.controls['DA.nature.description'].setValue('');
  }

  onDictSelect(id = null){
    this.resetAdressFormControls();
    if(this.form.controls.dict_id && this.form.controls['dict_id'].value != ''){
      let dict_id = id ? id : this.form.controls['dict_id'].value;
      //get dict address data
      this.dataService.getAsPromise(`admin/dapcs/dict_data/${dict_id}`)
      .then((result) => {
        if (result) {
          if(result.complement){
            this.form.controls['DA.localisation.complement'].setValue(result.complement);
          }
          if(result.code_postal){
            this.form.controls['DA.localisation.cp'].setValue(result.code_postal); 
          }
          if(result.localite){
            this.form.controls['DA.localisation.localite'].setValue(result.localite);
          }
          if(result.dict_description){
            this.form.controls['DA.nature.description'].setValue(result.dict_description);
          }          
        }
      })
      .catch((error) => {
        console.log('Server error dapc error', error);
      });
    }   
  }
  
  save(sendEmail: boolean = false) {

    this.send_email=sendEmail;
    if (sendEmail) {
      this.btn_send_loader = true;
      this.btn_send_disabled = true;
    } else {
      this.btn_save_loader = true;
      this.btn_save_disabled = true;
    }

    this.form.markAllAsTouched();

    if (this.dapc) {
      this.update();
    } else {
      this.create();
    }

  }

  create() {
    if (this.form.valid) {
      this.processFormValue('create');
      let requestObject=new Object(this.dapc);
      requestObject['is_duplicate']=false;
      this.dataService.postAsPromise('admin/dapcs', requestObject).then(res => {
        if (res && res.data) {
          this.dapc=new Dapc(res.data);         
          if(this.send_email){
            this.openAttachmentDialog(this.dapc);
          }
        }
        this.resetButtons();
      }).catch(err => {
        this.resetButtons();
        this.dapc = null;
        console.log('err', err);
      });
    } else {
      this.resetButtons();
      console.log("Form not valid");
    }
  }

  update() {  
    if (this.form.valid) {
      this.processFormValue('update');     
      this.dataService.putAsPromise(`admin/dapcs/${this.dapc.id}`, this.dapc).then(res => {
        if (res && res.data) {
          this.dapc.import(res.data);
          if(this.send_email){
            this.openAttachmentDialog(this.dapc);
          }
        } 
        this.resetButtons();
      }).catch(err => {
        this.resetButtons();
        this.dapc = null;
        console.log('err', err);
      });
    } else {
      this.resetButtons();
      console.log("Form not valid");
    }
  }

  sendEmail(){
    this.dataService
      .putAsPromise(`admin/dapcs/send/${this.dapc.id}`, this.dapc)
      .then((res) => {
        if (res && res.data) {
          this.onSendSuccess();
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }

  onAddContact(){
    const dialogRef = this.dialog.open(DapcContactDialog, {
      data: {
        item: null,
        from_dapc : true
      },
      width: '750px',
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {

      if(result && result != "bt_no"){

        this.form.controls['contact_id'].patchValue({
          id: result.id,
          label: 
            result.firstname +' '+
            result.lastname +' '+
            result.city     
        });

        this.AutocompleteField.loadField();
      }
    });
  }

  onSendSuccess() {
    const dialogRef = this.dialog.open(AlertDialog, {
      data: {
        text: this.translate.instant('MESSAGES.SEND_DAPC_SUCCESS'),
      },
      width: '300px',
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.dialog.closeAll();
    });
  }

  resetButtons(){
    this.btn_save_disabled = false;
    this.btn_send_disabled = false;
    this.btn_save_loader = false;
    this.btn_send_loader = false;
  }
 
  processFormValue(mode='create'){

    let data=new Object();
    
    if(mode == 'update'){
      this.dapc.data=[];
    }

    if(mode == 'create'){
      this.dapc=new Dapc();
    }

    //restructure form data
    Object.keys(this.form.controls).forEach(key => {

        if(key == 'contact_id'){
          this.dapc.dapc_contact_id=this.form.get(key).value;
        }
        else if(key == 'beuzit_contact_id'){
          this.dapc.beuzit_contact_id=this.form.get(key).value;
        }
        else if(key == 'dict_id'){
          this.dapc.dict_id=this.form.get(key).value;
        }
        else if(key == 'label'){
          this.dapc.label=this.form.get(key).value;
        }
        else if(key=='debutDate'){
          let formValue=this.form.get(key).value;
          if(formValue){
            let date=new Date(formValue);
            data['DA.nature.dateDebut.mm']=((date.getMonth() + 1) < 10 ? '0' : '')+ (date.getMonth() + 1);
            data['DA.nature.dateDebut.dd']=(date.getDate() < 10 ? '0' : '')+ date.getDate();
            data['DA.nature.dateDebut.yyyy']=date.getFullYear();
          }          
        }
        else if(key=='debutDateReglementation'){
          let formValue=this.form.get(key).value;
          if(formValue){
            let date=new Date(formValue);
            data['DA.reglementation.dateDebut.mm']=((date.getMonth() + 1) < 10 ? '0' : '')+ (date.getMonth() + 1);
            data['DA.reglementation.dateDebut.dd']=(date.getDate() < 10 ? '0' : '')+ date.getDate();
            data['DA.reglementation.dateDebut.yyyy']=date.getFullYear();
          }          
        }
        else{
          data[key]=this.form.controls[key].value?.toString();   
        }
    });
    this.dapc.data=data;
  }

  hasError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    return this.form.controls[controlName].hasError(errorName);
  } 

  openAttachmentDialog(dapc){
    const dialogRef = this.dialog.open(SendDapcDialog, {
      data: {
        item:dapc,
      },
      width: '300px',
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(result){
        this.disableForm();
        this.sendEmail();
      }
    });
  }

  disableForm(){
    this.form.disable();
    this.btn_send_disabled=true;
    this.btn_send_loader=true; 
    this.btn_save_disabled=true;    
    this.readonly=true;
  }

}

