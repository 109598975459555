
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Dapc } from 'src/app/models/Dapc';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'send-dapc-dialog',
  templateUrl: './send-dapc-dialog.component.html',
  styleUrls: ['./send-dapc-dialog.component.scss'],
})
export class SendDapcDialog implements OnInit {
  public form: FormGroup;
  public show_reject_input: boolean = false;
  public btn_save_disabled = false;
  public btn_save_loader = false;
  public selected: string = null;
  public fieldChanged = false;
  public attachment_max_size = 10;

  constructor(
    public dialogRef: MatDialogRef<SendDapcDialog>,
    private dataService: DataService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.form = new FormGroup({
      file: new FormControl(null,[this.sizeValidator()]),
    });
  }

  ngOnInit(): void {}

  save() {

    this.form.markAllAsTouched();
    if (this.form.valid) {

      if(!this.form.get('file').value){
        this.dialogRef.close( { dapc: this.data.item });
        return;
      }
      
      const formData: FormData = new FormData();
      let data = this.form.value;

      if (data != null && !!data['file'] && data['file'] instanceof File) {
        formData.append('file', data['file'], data['file'].name);
        
        this.btn_save_disabled = true;
        this.btn_save_loader = true;

        this.dataService
          .postAsPromise(`admin/dapcs/add_attachment/${this.data.item.id}`, formData)
          .then((res) => {
            if (res && res.data) {
              let dapc=new Dapc(res.data);
              this.dialogRef.close({ dapc: dapc });
            } else {
              this.dialogRef.close({ dapc: this.data.dapc });
            }

            this.btn_save_disabled = false;
            this.btn_save_loader = false;
          })
          .catch((err) => {
            console.log('add-dapc error: ', err);
            this.btn_save_disabled = false;
            this.btn_save_loader = false;
          });
      }
    }
  }

  onClose(){
    this.dialogRef.close();
  }

  hasError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    let field = this.form.controls[controlName];
    return field.hasError(errorName) && field.touched;
  }

  sizeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const file = control.value as File;
      if (!file) {
        return null;
      }

      if(file.size > 10000000 ){
        return { maxSize: true };
      }  
      else{
        return null;
      }

    };
  }
}

