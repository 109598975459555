// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: {
    root: 'https://demo-api.ma-dict.fr/api',
  },
  fileserver: {
    downloads: 'https://demo-api.ma-dict.fr/api/downloads',
    images: 'https://demo-api.ma-dict.fr/api/images',
    public: 'https://demo-api.ma-dict.fr',
  },
  auth: {
    providers: {
      google: { enabled: false }, // sign-in and register
      facebook: { enabled: false }, // sign-in and register
      email: {
        enabled: true,
        register: false,
        forgot: false,
        fields: {
          displayName: true,
          lastname: true,
          firstname: true,
          phone: true,
        },
      },
      phone: { enabled: false },
    },
    roles: {
      whitelist: null,
      blacklist: null,
    },
  },
  pusher: {
    enabled:false,
    key: 'xxxxxxxxxxxx',
    cluster: 'xx',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
